import { useRef, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import "./App.css";
import microPhoneIcon from "./microphone.svg";

function App() {

  const commands = [
    {
      command: "abre *",
      callback: (website) => {
        console.log("website: ",website);
        window.open("http://" + website.split(" ").join(""));
      },
    },
   {
      command: "Cuáles * requisitos * terreno",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video2.mp4#t=12,20';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Cuáles * requisitos * dirección * apertura * puerta",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video2.mp4#t=23,47';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Dáme * ejemplos * medidas * puertas * muros",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video2.mp4#t=49,88';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Dime * aclaraciones * instalación * producto",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video2.mp4#t=89,121';
        resetTranscript();
        stopHandle();
      }
    },
	{
      command: "Cómo identifico * modelo * accionador",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video4.mp4#t=5,12';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Cómo * modelo * bloqueado",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video4.mp4#t=13,18';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Cúal * longitud * vástago",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video4.mp4#t=13,18';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Muestra * gráfica * límites * utilización",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video4.mp4#t=19,32';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Cuáles * límites * uso * motor",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video4.mp4#t=19,32';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Muestra casos * motor funciona correctamente *",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video4.mp4#t=33,54';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Muestra caso * motor no funciona correctamente *",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video4.mp4#t=55,68';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Qué información * importante * electrocerradura",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video4.mp4#t=69,82';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Cómo * accionamiento manual * accionador",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video5.mp4#t=4,18';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Cómo desbloqueo * accionamiento manual",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video5.mp4#t=20,25';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Cómo * girar * llave * desbloqueo",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video5.mp4#t=26,32';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Muestra imágenes * estado del motor",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video5.mp4#t=33,37';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Muestra * motor desbloqueado",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video5.mp4#t=38,43';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Cómo * bloqueo * accionamiento automático",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video5.mp4#t=47,52';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Cómo giro * llave * accionamiento automático",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video5.mp4#t=53,60';
        resetTranscript();
        stopHandle();
      }
    },
    {
      command: "Cómo * retira * llave * cierra la tapa",
      callback: () => {
        console.log('Accionamiento manual');
        document.getElementById('idexpvid').src='./assets/videos/Video5.mp4#t=62,65';
        resetTranscript();
        stopHandle();
      }
    }
  ];

  const { transcript, resetTranscript } = useSpeechRecognition({ commands });

  const [isListening, setIsListening] = useState(false);
  const microphoneRef = useRef(null);
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return (
      <div className="mircophone-container">
        Browser is not Support Speech Recognition.
      </div>
    );
  }


  const handleListing = () => {
    setIsListening(true);
    microphoneRef.current.classList.add("listening");
    SpeechRecognition.startListening({
      continuous: true,
      language: 'es-MX'
    });
  };
  const stopHandle = () => {
    setIsListening(false);
    microphoneRef.current.classList.remove("listening");
    SpeechRecognition.stopListening();
    resetTranscript();
  };
  const handleReset = () => {
    stopHandle();
    resetTranscript();
  };
  return (
    <div className="microphone-wrapper">
      <div className="microphone-container">
        <div
          className="microphone-icon-container marginl"
          ref={microphoneRef}
          onClick={handleListing}>
          <img src={microPhoneIcon} className="microphone-icon" style={{ width: 50}}/>
        </div>
        <div className="microphone-status">
          {isListening ? "Escuchando" : "Click para iniciar"}
        </div>
        <div>
        {isListening && (
          <button className="microphone-stop btn" onClick={stopHandle}>
            Detener
          </button>
        )}
        </div>
      </div>
      <video id="idexpvid" src="" width="100%" controls autoPlay="autoplay" type="video/mp4"/>

      {transcript && (
        <div className="microphone-result-container">
          <div className="microphone-result-text">{transcript}</div>
          <button className="microphone-reset btn" onClick={handleReset}>
            Inicializar
          </button>
        </div>
      )}
    </div>
  );
}
export default App;
